import whitelabelDictionary from '@/config/dictionary'

const NewBusinessIcon = require('../../assets/images/onboarding/New_business.svg')
const ImproveCashflowIcon = require('../../assets/images/onboarding/Improve_cashflow.svg')
const GrowBusinessIcon = require('../../assets/images/onboarding/Grow_business.svg')
const purchaseBusinessOrPropertyIcon = require('../../assets/images/onboarding/purchase_business_or_property.svg')

const startNewBusiness = {
  title: "I'm starting a new business",
  description: {
    title: "This is the option to choose if you're starting or acquiring a new business.",
    text: "There's lots of support available; from start up loans and asset finance to help with finding an investor and improving your pitch deck."
  },
  category: 'startNewBusiness',
  icon: NewBusinessIcon
}

const growMyBusiness = {
  title: 'I want to grow my business',
  description: {
    title: 'Choose this option if you want to expand your business with new stock, staff, property or assets.',
    text: 'We can help you with everything from commercial mortgages and invoice finance to research and development grants.'
  },
  category: 'growMyBusiness',
  icon: GrowBusinessIcon
}

const improveCashflowDebt = {
  title: 'I want to improve my cashflow',
  description: {
    title: 'This option is for day-to-day cashflow and debt solutions.',
    text: 'We can match you with capital or fixed term loans as well as helping you with a commercial mortgage, grant or a tax advance.'
  },
  category: 'improveCashflowDebt',
  icon: ImproveCashflowIcon
}

// ab testing this goal with query param /stage?allOptions=true
export const purchaseBusinessOrProperty = {
  title: 'I want to buy a business or a property',
  description: {
    title: 'This option is for commercial mortgages/property finance.',
    text: "If you're looking to acquire a new business, or purchase property as part of your business, our team is here to help."
  },
  category: 'purchaseBusinessOrProperty',
  icon: purchaseBusinessOrPropertyIcon
}

// US/CAN only
const refinanceDebt = {
  title: 'I want to refinance debt',
  description: {
    title: 'This option is for day-to-day cashflow and debt solutions.',
    text: 'We can match you with capital or fixed term loans as well as helping you with a commercial mortgage, grant or a tax advance.'
  },
  category: 'improveCashflowDebt',
  icon: ImproveCashflowIcon
}

const onboardingConfig = {
  steps: ['Enter your details', 'Tell us more about your business', 'See your full funding matches'],
  goalsPage: {
    title: whitelabelDictionary.stagePage.title,
    description: whitelabelDictionary.stagePage.description,
    bulletPoints: whitelabelDictionary.stagePage.bulletPoints,
    goals: {
      uk: [startNewBusiness, growMyBusiness, improveCashflowDebt],
      us: [startNewBusiness, growMyBusiness, refinanceDebt]
    },
    finalOption: { text: "I'm not sure yet", category: 'notSure' },
    headerText: 'Already have an account?'
  },
  getStartedPage: {
    title: whitelabelDictionary.getStartedPage.title,
    editTitle: whitelabelDictionary.getStartedPage.editTitle,
    description: whitelabelDictionary.getStartedPage.description,
    contactUsOnNumber: '',
    agree: 'By clicking here you agree to our Privacy Policy & Terms of Use',
    buttonText: 'See your funding matches',
    otherFundingReasonOption: [{ label: 'Other', value: 'Other' }],
    companySearchRelatedFieldNames: ['companyName', 'companyNameNotDecided', 'country', 'region', 'county', 'district', 'tradingAs', 'notYetTrading', 'tradingStartDate'],
    editField: ['turnoverLastYear', 'sectors', 'fundingAmount', 'whenFundingNeeded'],
    passwordQuestion: {
      debounce: 500,
      fieldId: 'password',
      sectionId: 'staticQuestions',
      name: 'password',
      label: 'Choose a password',
      dataType: 'string',
      type: 'password',
      rules: {
        required: true,
        min_length: 8
      },
      errorMessages: {
        required: 'Please enter a valid password'
      },
      dependencies: null,
      displayOrder: 0,
      formFieldValue: null,
      value: null
    },
    staticQuestions: [
      {
        fieldId: 'country',
        sectionId: 'staticQuestions',
        name: 'country',
        label: 'Where is your business located?',
        dataType: 'string',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please tell us where your business is located'
        },
        options: [
          {
            value: 'Australia',
            label: 'Australia'
          },
          {
            value: 'Canada',
            label: 'Canada'
          },
          {
            value: 'Ireland',
            label: 'Ireland'
          },
          {
            value: 'South Africa',
            label: 'South Africa'
          },
          {
            value: 'United Kingdom',
            label: 'United Kingdom'
          },
          {
            value: 'United States of America',
            label: 'United States of America'
          }
        ],
        metadata: {
          optionsType: 'mainCountries',
          country: 'United Kingdom'
        },
        dependencies: null,
        displayOrder: 1,
        placeholder: 'Country',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      },
      {
        fieldId: 'region',
        sectionId: 'staticQuestions',
        name: 'region',
        label: 'Where is your business operating?',
        dataType: 'integer',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please choose a region, state or province'
        },
        options: [],
        metadata: {
          optionsType: 'regions',
          requiredParentField: 'country'
        },
        childOf: 'country',
        dependencyRule: 'or',
        dependencies: [
          {
            value: 'United Kingdom',
            field: 'country'
          },
          {
            value: 'Canada',
            field: 'country'
          },
          {
            value: 'Australia',
            field: 'country'
          },
          {
            value: 'South Africa',
            field: 'country'
          },
          {
            value: 'Ireland',
            field: 'country'
          },
          {
            value: 'United States of America',
            field: 'country'
          }
        ],
        displayOrder: 2,
        placeholder: 'Region, State or Province',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      },
      {
        fieldId: 'county',
        sectionId: 'staticQuestions',
        name: 'county',
        label: 'Select your county',
        dataType: 'integer',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please choose a county'
        },
        options: [],
        metadata: {
          optionsType: 'counties',
          requiredParentField: 'region'
        },
        childOf: 'region',
        dependencyRule: 'or',
        dependencies: [
          {
            value: '4',
            field: 'region'
          },
          {
            value: '3',
            field: 'region'
          },
          {
            value: '1',
            field: 'region'
          },
          {
            value: '2',
            field: 'region'
          }
        ],
        displayOrder: 3,
        placeholder: 'County',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      },
      {
        fieldId: 'district',
        sectionId: 'staticQuestions',
        name: 'district',
        label: 'Select your district',
        dataType: 'integer',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please choose a district'
        },
        options: [],
        metadata: {
          optionsType: 'districts',
          requiredParentField: 'county'
        },
        childOf: 'county',
        dependencyRule: 'or',
        dependencies: [
          {
            value: '1',
            field: 'county'
          },
          {
            value: '106',
            field: 'county'
          },
          {
            value: '107',
            field: 'county'
          },
          {
            value: '2',
            field: 'county'
          },
          {
            value: '3',
            field: 'county'
          },
          {
            value: '4',
            field: 'county'
          },
          {
            value: '6',
            field: 'county'
          },
          {
            value: '7',
            field: 'county'
          },
          {
            value: '8',
            field: 'county'
          },
          {
            value: '9',
            field: 'county'
          },
          {
            value: '10',
            field: 'county'
          },
          {
            value: '11',
            field: 'county'
          },
          {
            value: '108',
            field: 'county'
          },
          {
            value: '12',
            field: 'county'
          },
          {
            value: '13',
            field: 'county'
          },
          {
            value: '14',
            field: 'county'
          },
          {
            value: '15',
            field: 'county'
          },
          {
            value: '16',
            field: 'county'
          },
          {
            value: '17',
            field: 'county'
          },
          {
            value: '109',
            field: 'county'
          },
          {
            value: '18',
            field: 'county'
          },
          {
            value: '109',
            field: 'county'
          },
          {
            value: '19',
            field: 'county'
          },
          {
            value: '20',
            field: 'county'
          },
          {
            value: '21',
            field: 'county'
          },
          {
            value: '22',
            field: 'county'
          },
          {
            value: '23',
            field: 'county'
          },
          {
            value: '24',
            field: 'county'
          },
          {
            value: '25',
            field: 'county'
          },
          {
            value: '26',
            field: 'county'
          },
          {
            value: '27',
            field: 'county'
          },
          {
            value: '28',
            field: 'county'
          },
          {
            value: '29',
            field: 'county'
          },
          {
            value: '110',
            field: 'county'
          },
          {
            value: '30',
            field: 'county'
          },
          {
            value: '31',
            field: 'county'
          },
          {
            value: '32',
            field: 'county'
          },
          {
            value: '33',
            field: 'county'
          },
          {
            value: '34',
            field: 'county'
          },
          {
            value: '35',
            field: 'county'
          },
          {
            value: '36',
            field: 'county'
          },
          {
            value: '37',
            field: 'county'
          },
          {
            value: '39',
            field: 'county'
          },
          {
            value: '40',
            field: 'county'
          },
          {
            value: '41',
            field: 'county'
          },
          {
            value: '42',
            field: 'county'
          },
          {
            value: '43',
            field: 'county'
          }
        ],
        displayOrder: 4,
        placeholder: 'District',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      }
    ],
    form: {
      displayOrder: 1,
      isCompleted: false,
      formFields: [
        // dynamic forms here
        {
          fieldId: 'fundingReason',
          sectionId: 'staticQuestions',
          name: 'fundingReason',
          label: "What's the specific reason you need funding?",
          dataType: 'integer',
          type: 'select',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please tell us the specific reason you need funding'
          },
          options: [], // will be populated by fetch
          metadata: {
            optionsType: 'fundingRequiredTimescales',
            country: 'United Kingdom'
          },
          dependencies: null,
          displayOrder: 0,
          tooltip: `Need help choosing? Give us a call on ${whitelabelDictionary.contactNumber} and one of our friendly experts will be able to help you choose the right funding option.`,
          placeholder: 'Select',
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'firstName',
          sectionId: 'staticQuestions',
          name: 'firstName',
          label: "What's your first name?",
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please tell us your first name'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'lastName',
          sectionId: 'staticQuestions',
          name: 'lastName',
          label: "What's your last name?",
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please tell us your last name'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'contactNumber',
          sectionId: 'staticQuestions',
          name: 'contactNumber',
          label: "What's your phone number?",
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please tell us your phone number'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'email',
          sectionId: 'staticQuestions',
          name: 'email',
          label: "What's your business email address?",
          dataType: 'string',
          type: 'text',
          debounce: 1000,
          confirmed: true,
          rules: {
            required: true,
            email: true
          },
          errorMessages: {
            required: 'Please tell us your business email address',
            regex: 'Please enter a valid email address'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'confirmEmail',
          sectionId: 'staticQuestions',
          name: 'confirmEmail',
          label: 'Please confirm your email address',
          dataType: 'string',
          type: 'text',
          debounce: 1000,
          rules: {
            required: true,
            confirmed: 'confirmation'
          },
          errorMessages: {
            required: 'Please confirm your email address',
            regex: 'Please enter a valid email address',
            confirmed: 'Email addresses must match'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          childOf: 'confirmEmail',
          fieldId: 'isEmailMarketing',
          sectionId: 'staticQuestions',
          name: 'isEmailMarketing',
          label: 'Send me Swoop updates and product information',
          dataType: 'bool',
          type: 'checkbox',
          displayOrder: 0,
          dependencies: null,
          formFieldValue: null,
          value: null
        }
      ],
      companyNameQuestions: [
        {
          fieldId: 'companyName',
          sectionId: 'staticQuestions',
          name: 'companyName',
          label: 'What is your company name?',
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please enter a value'
          },
          dependencies: null,
          displayOrder: 1,
          formFieldValue: null,
          value: null
        },
        {
          childOf: 'companyName',
          fieldId: 'companyNameNotDecided',
          sectionId: 'staticQuestions',
          name: 'companyNameNotDecided',
          fieldToDisable: 'companyName',
          label: "I haven't decided",
          dataType: 'bool',
          type: 'checkbox',
          displayOrder: 2,
          dependencies: null,
          formFieldValue: null,
          value: null
        }
      ]
    }
  },
  detailsPage: {
    description: 'We ask these questions so that we can match you with providers in our marketplace.',
    formRedirectOnOtherFundingReason: {
      startNewBusiness: 'startABusiness',
      growMyBusiness: 'businessExpansion',
      improveCashflowDebt: 'improveCashflow',
      notSure: 'businessExpansion'
    }
  },
  signupUser: {
    title: "Let's get started!",
    description: ['The first step is to get some information from you.', 'These questions help us understand your funding needs.', 'Need help? Contact us on '],
    agree: 'By clicking here you agree to our Privacy Policy & Terms of Use',
    buttonText: 'See your funding matches',
    companySearchRelatedFieldNames: ['companyName', 'companyNameNotDecided', 'country', 'region', 'county', 'district', 'tradingAs', 'notYetTrading', 'tradingStartDate'],
    editField: ['turnoverLastYear', 'sectors'],
    staticQuestions: [
      {
        fieldId: 'country',
        sectionId: 'staticQuestions',
        name: 'country',
        label: 'Where is your business located?',
        dataType: 'string',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please tell us where your business is located'
        },
        options: [
          {
            value: 'Australia',
            label: 'Australia'
          },
          {
            value: 'Canada',
            label: 'Canada'
          },
          {
            value: 'Ireland',
            label: 'Ireland'
          },
          {
            value: 'South Africa',
            label: 'South Africa'
          },
          {
            value: 'United Kingdom',
            label: 'United Kingdom'
          },
          {
            value: 'United States of America',
            label: 'United States of America'
          }
        ],
        metadata: {
          optionsType: 'mainCountries',
          country: 'United Kingdom'
        },
        dependencies: null,
        displayOrder: 1,
        placeholder: 'Country',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      },
      {
        fieldId: 'region',
        sectionId: 'staticQuestions',
        name: 'region',
        label: 'Where is your business operating?',
        dataType: 'integer',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please choose a region, state or province'
        },
        options: [],
        metadata: {
          optionsType: 'regions',
          requiredParentField: 'country'
        },
        childOf: 'country',
        dependencyRule: 'or',
        dependencies: [
          {
            value: 'United Kingdom',
            field: 'country'
          },
          {
            value: 'Canada',
            field: 'country'
          },
          {
            value: 'Australia',
            field: 'country'
          },
          {
            value: 'Ireland',
            field: 'country'
          },
          {
            value: 'South Africa',
            field: 'country'
          },
          {
            value: 'United States of America',
            field: 'country'
          }
        ],
        displayOrder: 2,
        placeholder: 'Region, State or Province',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      },
      {
        fieldId: 'county',
        sectionId: 'staticQuestions',
        name: 'county',
        label: 'Select your county',
        dataType: 'integer',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please choose a county'
        },
        options: [],
        metadata: {
          optionsType: 'counties',
          requiredParentField: 'region'
        },
        childOf: 'region',
        dependencyRule: 'or',
        dependencies: [
          {
            value: '4',
            field: 'region'
          },
          {
            value: '3',
            field: 'region'
          },
          {
            value: '1',
            field: 'region'
          },
          {
            value: '2',
            field: 'region'
          }
        ],
        displayOrder: 3,
        placeholder: 'County',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      },
      {
        fieldId: 'district',
        sectionId: 'staticQuestions',
        name: 'district',
        label: 'Select your district',
        dataType: 'integer',
        type: 'select',
        rules: {
          required: true
        },
        errorMessages: {
          required: 'Please choose a district'
        },
        options: [],
        metadata: {
          optionsType: 'districts',
          requiredParentField: 'county'
        },
        childOf: 'county',
        dependencyRule: 'or',
        dependencies: [
          {
            value: '1',
            field: 'county'
          },
          {
            value: '106',
            field: 'county'
          },
          {
            value: '107',
            field: 'county'
          },
          {
            value: '2',
            field: 'county'
          },
          {
            value: '3',
            field: 'county'
          },
          {
            value: '4',
            field: 'county'
          },
          {
            value: '6',
            field: 'county'
          },
          {
            value: '7',
            field: 'county'
          },
          {
            value: '8',
            field: 'county'
          },
          {
            value: '9',
            field: 'county'
          },
          {
            value: '10',
            field: 'county'
          },
          {
            value: '11',
            field: 'county'
          },
          {
            value: '108',
            field: 'county'
          },
          {
            value: '12',
            field: 'county'
          },
          {
            value: '13',
            field: 'county'
          },
          {
            value: '14',
            field: 'county'
          },
          {
            value: '15',
            field: 'county'
          },
          {
            value: '16',
            field: 'county'
          },
          {
            value: '17',
            field: 'county'
          },
          {
            value: '109',
            field: 'county'
          },
          {
            value: '18',
            field: 'county'
          },
          {
            value: '109',
            field: 'county'
          },
          {
            value: '19',
            field: 'county'
          },
          {
            value: '20',
            field: 'county'
          },
          {
            value: '21',
            field: 'county'
          },
          {
            value: '22',
            field: 'county'
          },
          {
            value: '23',
            field: 'county'
          },
          {
            value: '24',
            field: 'county'
          },
          {
            value: '25',
            field: 'county'
          },
          {
            value: '26',
            field: 'county'
          },
          {
            value: '27',
            field: 'county'
          },
          {
            value: '28',
            field: 'county'
          },
          {
            value: '29',
            field: 'county'
          },
          {
            value: '110',
            field: 'county'
          },
          {
            value: '30',
            field: 'county'
          },
          {
            value: '31',
            field: 'county'
          },
          {
            value: '32',
            field: 'county'
          },
          {
            value: '33',
            field: 'county'
          },
          {
            value: '34',
            field: 'county'
          },
          {
            value: '35',
            field: 'county'
          },
          {
            value: '36',
            field: 'county'
          },
          {
            value: '37',
            field: 'county'
          },
          {
            value: '39',
            field: 'county'
          },
          {
            value: '40',
            field: 'county'
          },
          {
            value: '41',
            field: 'county'
          },
          {
            value: '42',
            field: 'county'
          },
          {
            value: '43',
            field: 'county'
          }
        ],
        displayOrder: 4,
        placeholder: 'District',
        dateCreated: '0001-01-01T00:00:00',
        createdBy: null,
        jsonTemp: null,
        formFieldValue: null,
        value: null
      }
    ],
    form: {
      displayOrder: 1,
      isCompleted: false,
      formFields: [
        // dynamic forms here
        {
          fieldId: 'firstName',
          sectionId: 'staticQuestions',
          name: 'firstName',
          label: "What's your first name?",
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please tell us your first name'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'lastName',
          sectionId: 'staticQuestions',
          name: 'lastName',
          label: "What's your last name?",
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please tell us your last name'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'contactNumber',
          sectionId: 'staticQuestions',
          name: 'contactNumber',
          label: "What's your phone number?",
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please tell us your phone number'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'email',
          sectionId: 'staticQuestions',
          name: 'email',
          label: "What's your business email address?",
          dataType: 'string',
          type: 'text',
          debounce: 1000,
          confirmed: true,
          rules: {
            required: true,
            email: true
          },
          errorMessages: {
            required: 'Please tell us your business email address',
            regex: 'Please enter a valid email address'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          fieldId: 'confirmEmail',
          sectionId: 'staticQuestions',
          name: 'confirmEmail',
          label: 'Please confirm your email address',
          dataType: 'string',
          type: 'text',
          debounce: 1000,
          rules: {
            required: true,
            confirmed: 'confirmation'
          },
          errorMessages: {
            required: 'Please confirm your email address',
            regex: 'Please enter a valid email address',
            confirmed: 'Email addresses must match'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        },
        {
          childOf: 'confirmEmail',
          fieldId: 'isEmailMarketing',
          sectionId: 'staticQuestions',
          name: 'isEmailMarketing',
          label: 'Send me Swoop updates and product information',
          dataType: 'bool',
          type: 'checkbox',
          displayOrder: 0,
          dependencies: null,
          formFieldValue: null,
          value: null
        },
        {
          debounce: 500,
          fieldId: 'password',
          sectionId: 'staticQuestions',
          name: 'password',
          label: 'Choose a password',
          dataType: 'string',
          type: 'password',
          rules: {
            required: true,
            min_length: 8
          },
          errorMessages: {
            required: 'Please enter a valid password'
          },
          dependencies: null,
          displayOrder: 0,
          formFieldValue: null,
          value: null
        }
      ],
      companyNameQuestions: [
        {
          fieldId: 'companyName',
          sectionId: 'staticQuestions',
          name: 'companyName',
          label: 'What is your company name?',
          dataType: 'string',
          type: 'text',
          rules: {
            required: true
          },
          errorMessages: {
            required: 'Please enter a value'
          },
          dependencies: null,
          displayOrder: 1,
          formFieldValue: null,
          value: null
        },
        {
          childOf: 'companyName',
          fieldId: 'companyNameNotDecided',
          sectionId: 'staticQuestions',
          name: 'companyNameNotDecided',
          fieldToDisable: 'companyName',
          label: "I haven't decided",
          dataType: 'bool',
          type: 'checkbox',
          displayOrder: 2,
          dependencies: null,
          formFieldValue: null,
          value: null
        }
      ]
    }
  }
}

export default onboardingConfig
