<template>
  <div class="stage-wrapper">
    <header class="onboarding-header">
      <span>{{ goalPageData.headerText }}</span>
      <router-link :to="{ name: 'login' }" tag="button">
        Sign in
      </router-link>
    </header>

    <div class="content">
      <h1 class="title">{{ goalPageData.title }}</h1>
      <p class="description">{{ goalPageData.description }}</p>

      <div class="bulletpoint-container">
        <p v-for="point of goalPageData.bulletPoints" :key="point" class="bulletpoint">
          <TickIcon />
          {{ point }}
        </p>
      </div>
      <div class="loader-container" v-if="loading">
        <Spinner :size="64" :loader="true" color />
      </div>
      <div v-else>
        <StageOptions :goals="goals" :finalOption="goalPageData.finalOption" />
      </div>
    </div>
  </div>
</template>

<script>
import StageOptions from '../../components/StageOptions.vue'
import TickIcon from '@/assets/images/onboarding/tick-icon.svg?inline'
import Spinner from '@/components/Spinner'
import onboardingConfig, { purchaseBusinessOrProperty } from '../../config'
import config from '@/config/global'
import deepLinking from '@/mixins/deepLinking'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import { authGetters, groupRoutine } from '@/store/modules/auth/routines'
import { saveStageGoal } from '@/store/modules/onboarding/routines'
import { tenantGetters } from '@/store/modules/tenant/routines'
import { amplitudeEvents, defaultGroupId } from '@/utils/constants'
import { getGroupInformation } from '@/api/group'
import { mapGetters, mapActions } from 'vuex'
import { dynamicFormListGetters, updateDynamicFormListRoutine } from '@/store/modules/dynamicFormList/routines'

export default {
  name: 'stage',
  mixins: [amplitudeTracking, deepLinking],
  components: { StageOptions, Spinner, TickIcon },
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    this.trackPageView()
    this.saveStageGoal(null)
    if (this.$route.query.groupId) {
      // this user was invited to a group
      getGroupInformation(this.$route.query.groupId).then(({ data }) => {
        data.fromInvite = true
        data.inviteSource = window.location.href
        data.isExternalGroup = this.$route.query.groupId !== defaultGroupId
        this.setGroup(data)
      })
    }
    this.updateDynamicFormList()
    this.loading = false
  },
  computed: {
    ...mapGetters({
      tenantName: tenantGetters.TENANT_NAME,
      user: authGetters.USER,
      dynamicFormList: dynamicFormListGetters.DYNAMIC_FORM_LIST
    }),
    goalPageData() {
      return onboardingConfig.goalsPage
    },
    enabledCategories() {
      const categorySet = new Set()
      if (!this.dynamicFormList.length) return []

      this.dynamicFormList.forEach(form => {
        if (!config.whitelabel.journey.disabledGoals.includes(form.name) && form.category) {
          form.category.split(',').forEach(item => categorySet.add(item))
        }
      })
      return [...categorySet].filter(category => !config.whitelabel.journey.disabledStageGoals.includes(category))
    },
    goals() {
      let goals
      if (this.tenantName === 'canada') goals = onboardingConfig.goalsPage.goals.us
      if (this.tenantName === 'uk' || this.tenantName === 'aus') goals = onboardingConfig.goalsPage.goals.uk
      if (this.$route.query.allOptions) goals = this.handleAbTestGoal(goals)
      return this.checkDisabledGoals(goals)
    }
  },
  methods: {
    ...mapActions({
      saveStageGoal: saveStageGoal.TRIGGER,
      setGroup: groupRoutine.TRIGGER,
      updateDynamicFormList: updateDynamicFormListRoutine.TRIGGER
    }),
    handleAbTestGoal(goals) {
      return goals.find(goal => goal.category === 'purchaseBusinessOrProperty') ? goals : [...goals, purchaseBusinessOrProperty]
    },
    checkDisabledGoals(goals) {
      return goals?.filter(goal => this.enabledCategories.includes(goal.category)) || []
    },
    trackPageView() {
      this.$ma.trackEvent(amplitudeEvents.pageViews.STAGE)
      this.trackPageViewWithUtmParams('Stage')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.stage-wrapper {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  color: $color-primary;
}

.onboarding-header {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px;
  span {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: $color-primary;
    text-decoration: none;
  }
  button {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 80px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--color-secondary-100);
    border-radius: 10px;
    background-color: transparent;
    color: $color-general-headers;
    &:hover {
      cursor: pointer;
      color: white;
      background-color: var(--color-primary-400);
    }
  }
}

.content {
  margin: 24px auto;
  flex-direction: column;
  max-width: 1600px;
  display: flex;
  padding: 24px;

  .title {
    margin: 4px 0;
  }

  .description {
    color: var(--color-primary-400);
    font-size: 18px;
    font-weight: 600;
    margin: 6px 0;
  }

  .bulletpoint-container {
    margin: 0;
  }

  .bulletpoint {
    margin: 4px 0;
    line-height: 14px;
    font-size: 14px;

    .check-icon {
      fill: $color-stage-page-icon-fill
    }
  }

  .loader-container {
    display: flex;
    justify-self: center;
    align-self: center;
  }

  .button {
    text-decoration: none;
    width: fit-content;
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .onboarding-header span {
    display: none;
  }
  .onboarding-header {
    button {
      width: 155px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .stage-wrapper {
    padding: 0px;
  }

  .bulletpoint-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
  }
  .bulletpoint {
    line-height: 16px;
    margin: 0;
  }
}
</style>
