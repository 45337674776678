<template>
  <div class="stage-wrapper">
    <div class="goal-list">
      <div
        v-for="(goal, index) in goals"
        :key="goal.key"
        :class="{
          'goal-tile': true,
          hidden: selectedTileIndex === index
        }"
        @click="() => handleHoverEvent('click', index)"
        @mouseover="() => handleHoverEvent('mouseover', index)"
        @mouseleave="() => resetSelected()"
      >
        <component v-if="selectedTileIndex !== index" :is="getSvgComponent(goal.category)" />
        <h4 class="tile-title">{{ goal.title }}</h4>
        <div v-if="selectedTileIndex === index" class="hover-container" @click="() => handleChoice(goal.category)">
          <div class="title-section">
            <p class="hover-container-title">{{ goal.description.title }}</p>
            <p class="hover-paragraph">{{ goal.description.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <button class="button" @click="() => handleChoice(finalOption.category)">{{ finalOption.text }}</button>
  </div>
</template>

<script>
import { saveStageGoal } from '@/store/modules/onboarding/routines'
import { mapActions } from 'vuex'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import deepLinking from '@/mixins/deepLinking'
import NewBusinessIcon from '@/assets/images/onboarding/New_business.svg?inline'
import GrowBusinessIcon from '@/assets/images/onboarding/Grow_business.svg?inline'
import ImproveCashflowIcon from '@/assets/images/onboarding/Improve_cashflow.svg?inline'
import purchaseBusinessOrPropertyIcon from '@/assets/images/onboarding/purchase_business_or_property.svg?inline'

export default {
  name: 'stage-options',
  mixins: [deepLinking, amplitudeTracking],
  components: { NewBusinessIcon, GrowBusinessIcon, ImproveCashflowIcon, purchaseBusinessOrPropertyIcon },

  data() {
    return {
      selectedTileIndex: null,
      selectEventHasFired: false,
      firedEvents: []
    }
  },
  props: {
    goals: {
      required: true,
      type: Array
    },
    finalOption: {
      required: true,
      type: Object
    }
  },
  methods: {
    ...mapActions({
      saveStageGoal: saveStageGoal.TRIGGER
    }),
    resetSelected() {
      this.selectedTileIndex = null
    },
    trackAmplitudeStageGoalHoverEvent(index) {
      const goal = this.goals[index]
      if (!this.firedEvents.includes(goal.category)) {
        this.$ma.trackEvent({
          eventType: 'stage goal hovered',
          eventProperties: {
            question: goal.category || ''
          }
        })
      }
      if (!this.firedEvents.includes(goal.category)) {
        this.firedEvents = [...this.firedEvents, goal.category]
      }
    },
    trackAmplitudeStageGoalChoiceEvent(category) {
      this.$ma.trackEvent({
        eventType: 'stage goal selected',
        eventProperties: {
          goalSelected: category
        }
      })
    },
    handleHoverEvent(eventType, index) {
      this.selectedTileIndex = index
      if (eventType === 'click') {
        this.trackAmplitudeStageGoalHoverEvent(index)
        this.selectEventHasFired = true
      }
    },
    handleChoice(category) {
      this.trackAmplitudeStageGoalChoiceEvent(category)
      this.saveStageGoal(category)
      this.$router.push({ name: 'get-started' })
    },
    getSvgComponent(goalName) {
      switch (goalName) {
        case 'growMyBusiness':
          return 'GrowBusinessIcon'
        case 'improveCashflowDebt':
          return 'ImproveCashflowIcon'
        case 'purchaseBusinessOrProperty':
          return 'purchaseBusinessOrPropertyIcon'
        default:
          return 'NewBusinessIcon'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.spinner-container {
  @include flex-display(column, center, center);
}

.goal-tile {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 8px;
  height: 220px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;

  .tile-icon {
    fill: $color-stage-page-icon-fill;
  }
  &.hidden {
    z-index: 2;
    outline: none;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    cursor: pointer;

    .tile-title {
      display: none;
    }

    .tile-icon {
      display: none;
      fill: $color-stage-page-icon-fill;
    }

    .hover-container {
      display: flex;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      padding: 16px;

      .hover-container-title {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .isSelected {
    outline: none;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    cursor: pointer;
    z-index: 2;

    .tile-title {
      display: none;
    }

    .tile-icon {
      display: none;
    }

    .hover-container {
      display: flex;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      padding: 16px;

      .hover-container-title {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
  .hover-container {
    @include flex-display(column, center, center);

    display: none;
    opacity: 0;

    .hover-paragraph {
      margin: 8px 0;
      text-align: center;
      letter-spacing: -0.4px;
      line-height: 18px;
      font-size: 13px;
      font-weight: 500;
      color: var(--color-primary-400);
      padding: 0 12px;
    }
  }
}

.goal-list {
  margin: 24px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.goal-text {
  margin: 8px;
}

.tile-title {
  margin: 20px;
}

.button {
  width: fit-content;
  background-color: #f9fafb;
  border: 1px solid #e7e7e7;
}

@media only screen and (max-width: 768px) {
  .goal-list {
    margin: 16px 0 0 0;
    flex-direction: column;

    .goal-tile {
      margin: 8px auto;
    }
    .middle-option {
      margin: auto;
    }
  }
  .button {
    margin: auto;
  }
}
</style>
